<template>
    <div class="main-contents code">
        <div class="tit">능력단위요소 등록/수정<img src="/images/admin/list_shortcut.png" alt="목록가기" @click="movePage('back')"/></div>
        <div class="search-box ncs">
            <div class="sub_btn" @click="save()">+ 저장</div>
            <div class="sub_btn dl" @click="remove()">삭제</div>
            <div class="sub_btn dr" @click="seqDown()"><img src="/images/admin/top_down_btn.png" alt="다운"/></div>
            <div class="sub_btn dr" @click="seqUp()"><img src="/images/admin/top_up_btn.png" alt="업"/></div>
            <div class="sub_btn dl reset" @click="reset()">초기화</div>
        </div>
        <!-- NCS 자가진단 문항 수정 -->
        <div class="Board type3 cf ncs">
            <div class="cf_title">NCS 자가진단 문항 수정</div>
            <div class="catg">[ 정보통신 ]  1. {{this.midName}} 〉 2. {{this.minorName}} 〉 3. {{this.detailName}} 〉 4. {{this.abilName}} 〉 <span>5. {{this.elemName}}</span></div>
            <table class="Board_type3 admin">
                <colgroup>
                    <col width="4%">
                    <col width="7%">
                    <col width="7%">
                    <col width="21%">
                    <col width="40%">
                    <col width="21%">
                </colgroup> 
                <thead>
                    <tr>
                        <th><input type="checkbox" @click="allCheck()" v-model="checkBox"></th>
                        <th>사용여부</th>
                        <th>문항번호</th>
                        <th>자가진단문항코드</th>
                        <th>문항내용</th>
                        <th>비고</th>
                    </tr>
                </thead>
                <tbody v-if="selfDiaList.length > 0">
                    <tr v-for="(item, idx) in selfDiaList" :key="idx">
                        <td class="score">
                            <input type="checkbox" v-model="item.check">
                        </td>

                        <td class="score">사용</td>

                        <td class="score">{{idx + 1}}</td>
                        <td class="score">
                            <InputComp tagName="div" type="text" v-model="item.selfDiagQeusCd" maxByte="10" :rules="{'required':true}" title="자간진단문항코드"/>
                        </td>
                        <td class="score">
                            <InputComp type="text" v-model="item.qeusCont" />
                        </td>
                        <td class="score">
                            <InputComp type="text" v-model="item.note" maxByte="300" />
                        </td>
                    </tr>
                </tbody>
                <tbody v-else>
                <!-- 관련내용 없을 경우 -->
                    <tr class="none">
                        <td colspan="6" class="none">등록된 자가진단 문항이 없습니다!</td>
                    </tr>
                </tbody>
            </table>
            <div class="plus_btn"><img src="/images/admin/plus_btn.png" alt="추가버튼" @click="add()"/></div>
        </div>
        <!-- 화면 up & down 버튼 -->
        <div class="screen_btn">
            <img src="/images/admin/screen-top_btn.png" alt="탑버튼"/>
            <img src="/images/admin/screen-down_btn.png" alt="다운버튼"/>
        </div>
    </div>
</template>

<script>

   export default {
    data() {
        return {
            midName: this.$route.params.midName,
            minorName: this.$route.params.minorName,
            detailName: this.$route.params.detailName,
            abilName: this.$route.params.abilName,
            elemName: this.$route.params.elemName,
            elemSeq: this.$route.params.elemSeq,
            selfDiaList: [],
            // insertSelfDiaList : [],
            // deleteSelfDiaList : [],
            // updateSelfDiaList : [],

            selfDiaDetail : {
                useYn : '',
                qeusNo : '',
                selfDiagQeusCd : '',
                qeusCont : '',
                note : '',
            },

            checkBox : false,

        };
    },
    beforeMount() {
        // console.log('param',this.$route.params);
        this.loadSelfDiaList();
        // this.insertSelfDiaList = this.selfDiaList;
    },
    methods: {

        movePage(div) {
            if (div == "back")
                this.$router.go(-1);
            else
                this.$router.push("MAN936M04");
        },

        loadSelfDiaList() {
            var param = {};
            param.elemSeq = this.elemSeq;
            this.$.httpPost("/api/main/adm/ncsinout/loadSelfDiaList", param)
                .then(res => {
                    // console.log("loadSelfDiaList RESULT", res);
                    this.selfDiaList = res.data.selfDiaList;
            }).catch(this.$.httpErrorCommon);
        },

        reset() {
            this.checkBox = false;
            // this.deleteSelfDiaList = [];
            // this.insertSelfDiaList = [];
            // this.updateSelfDiaList = [];
            this.loadSelfDiaList();
        },

        add() {
            this.selfDiaList.push({
                check : false,
                useYn : 'Y',
                qeusNo : this.selfDiaList.length + 1,
                qeusCont : '',
                selfDiagQeusCd : '',
                note : null
            })
            // console.log(this.selfDiaList);

        },

        remove() {
            // for(var item of this.selfDiaList) {
            //     if(item.check == true) {
            //         console.log(item);
            //         this.selfDiaList.splice(item.qeusNo-1 , 1);
            //     }
            // }

            var deleteList = [];
            
            for(var i = this.selfDiaList.length-1 ; i >= 0; i--) {
                if(this.selfDiaList[i].check == true) {
                    if(this.selfDiaList[i].selfDiagQeusSeq == null || this.selfDiaList[i].selfDiagQeusSeq == '') {
                        this.selfDiaList.splice(i , 1);
                    } else {
                        // console.log(this.selfDiaList[i]);
                        deleteList.push(this.selfDiaList[i]);
                    }
                }
            }

            // console.log(deleteList);

            if(confirm('삭제하시겠습니까?')) {
                // console.log('delete!!!');
                // console.log(deleteList);
                var param = {};
                param.elemSeq = this.elemSeq;
                param.deleteSelfDiaList = deleteList;
                // console.log('delete param', param);
                this.$.httpPost('/api/main/adm/ncsinout/deleteSelfDiaQues', param)
                    .then(() => {
                        alert("삭제되었습니다.");
                        this.loadSelfDiaList();
                    }).catch(this.$.httpErrorCommon);  
            }
        },

        seqUp() {
            // console.log(11111111);
            for(var i = 1; i < this.selfDiaList.length; i++) {
                if(this.selfDiaList[i-1].check == true) {
                    continue;
                }
                if(this.selfDiaList[i].check == true) {
                    var temp = this.selfDiaList[i-1];
                    this.selfDiaList[i-1] = this.selfDiaList[i];
                    this.selfDiaList[i] = temp;
                }
            }
            this.$forceUpdate();
            // console.log(this.selfDiaList);   
        },

        seqDown() {
            for(var i = this.selfDiaList.length-2; i >= 0 ; i--) {
                if(this.selfDiaList[i+1].check == true) {
                    continue;
                }
                if(this.selfDiaList[i].check == true) {
                    var temp = this.selfDiaList[i+1];
                    this.selfDiaList[i+1] = this.selfDiaList[i];
                    this.selfDiaList[i] = temp;
                }
            }
            this.$forceUpdate();
            // console.log(this.selfDiaList);
        },

        save() {
            // console.log('check', this.selfDiaList, this.selfDiaList.index);
            for(var i = 0; i < this.selfDiaList.length; i++) {
                this.selfDiaList[i].qeusNo = i+1;
            }
            // console.log('check', this.selfDiaList);

            
            var param = {};
            param.elemSeq = this.elemSeq;
            param.selfDiaList = this.selfDiaList;
            // console.log(param);

            if(confirm('저장하시겠습니까?')) {
                this.$.httpPost('/api/main/adm/ncsinout/setSelfDiaQues', param)
                .then(() => {
                    alert("저장되었습니다.");
                    this.loadSelfDiaList();
                }).catch(this.$.httpErrorCommon);    
            }
        },

        allCheck() {
            if(this.checkBox) {
                this.checkBox = false;
                for(var item of this.selfDiaList) {
                    item.check = false;
                }
            } else {
                this.checkBox = true;
                for(var item1 of this.selfDiaList) {
                    item1.check = true;
                }
            }
            // this.$forceUpdate();
        }
    },
    
}
</script>